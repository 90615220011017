<template>
  <Layout>
    <el-tabs v-model="activeName">
      <!-- eslint-disable vue/max-attributes-per-line -->
      <el-tab-pane label="黑名单设备" name="deviceBlacklist" />
      <el-tab-pane label="白名单设备" name="deviceWhitelist" />
      <el-tab-pane label="国家设置" name="nationBlacklist" />
      <el-tab-pane label="客户端版本" name="clientVersion" />
    </el-tabs>

    <component :is="activeName" :vest="VEST_MAP.FALSE">
      {{ activeName }} 组件未发现
    </component>
  </Layout>
</template>

<script>
import deviceBlacklist from './components/deviceBlacklist'
import deviceWhitelist from './components/deviceWhitelist'
import nationBlacklist from './components/nationBlacklist'
import clientVersion from './components/clientVersion'

const VEST_MAP = {
  TRUE: 0,
  FALSE: 1
}
export default {
  components: {
    deviceBlacklist,
    deviceWhitelist,
    nationBlacklist,
    clientVersion
  },
  data () {
    return {
      VEST_MAP,
      activeName: 'deviceBlacklist'
    }
  }
}
</script>

<style>

</style>
