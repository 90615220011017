import { render, staticRenderFns } from "./deviceWhitelist.vue?vue&type=template&id=f0542ae4&scoped=true"
import script from "./deviceWhitelist.vue?vue&type=script&lang=js"
export * from "./deviceWhitelist.vue?vue&type=script&lang=js"
import style0 from "./deviceWhitelist.vue?vue&type=style&index=0&id=f0542ae4&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0542ae4",
  null
  
)

export default component.exports