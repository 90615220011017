<template>
  <section>
    <div class="overview">
      <div class="items">
        <div class="num">
          {{ listInfo.deviceNum }}
        </div>
        <div class="name">
          累计白名单设备数(包括今日)
        </div>
      </div>
      <div class="items">
        <div class="num">
          {{ listInfo.yesterdayDeviceNum }}
        </div>
        <div class="name">
          昨日白名单设备数(UTC时间)
        </div>
      </div>
      <div class="items">
        <div class="num">
          {{ listInfo.ipNum }}
        </div>
        <div class="name">
          累计白IP数(包括今日)
        </div>
      </div>
      <div class="items">
        <div class="num">
          {{ listInfo.yesterdayIpNum }}
        </div>
        <div class="name">
          昨日白IP数(UTC时间)
        </div>
      </div>
    </div>
    <BHeader style="margin: 40px 0;">
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
      >
        <el-form-item>
          <el-date-picker
            v-model="searchDate"
            :picker-options="pickerOptions"
            type="daterange"
            format="yyyy-MM-dd"
            unlink-panels
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="handleSearch"
          />
        </el-form-item>
        <el-form-item
          prop="appkey"
        >
          <el-select
            v-model="formData.appkey"
            placeholder="请选择马甲包"
            clearable
            @change="handleSearch"
          >
            <el-option
              v-for="(item, index) in appKeysList"
              :key="index"
              :label="item.name"
              :value="item.appKey"
            />
          </el-select>
        </el-form-item>

        <el-form-item prop="udid">
          <el-input
            v-model="formData.udid"
            clearable
            placeholder="请输入设备ID"
            @change="handleSearch"
          />
        </el-form-item>
        <el-form-item prop="ip">
          <el-input
            v-model="formData.ip"
            clearable
            placeholder="请输入登录IP"
            @change="handleSearch"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
    </BHeader>

    <el-table
      v-loading="listInfo.loading"
      :data="listInfo.list"
      stripe
      style="width: 100%"
    >
      <el-table-column
        label="设备ID"
        prop="udid"
        width="180px"
      />
      <el-table-column
        label="登录IP"
        prop="ip"
        width="140px"
      />
      <el-table-column
        label="登录应用"
        prop="appKey"
        width="160px"
        :formatter="appKeyFormatter"
      />
      <el-table-column
        label="登录壳版本号"
        prop="version"
        width="180px"
      />
      <el-table-column
        label="登录国家"
        prop="nation"
        width="120px"
      />
      <el-table-column
        label="标记原因"
        prop="reason"
      />

      <el-table-column
        label="标记时间(UTC)"
        prop="updateTime"
        :formatter="dataFomat"
        width="180px"
      />

      <el-table-column
        label="操作"
        width="160px"
      >
        <template #default="{row}">
          <el-button
            :loading="row.loading"
            @click="updateUserBlackAndWhiteList(row)"
          >
            移出白名单
          </el-button>
        </template>
      </el-table-column>

      <template #empty>
        暂无搜索结果
      </template>
    </el-table>
    <Pagination
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryDataList"
    />
  </section>
</template>

<script>
import {
  getBlackAndWhiteDeviceList,
  getAppKeys,
  removeDevice
} from '@/api/blacklist.js'

export default {
  data () {
    return {
      groupList: [],
      searchDate: [],
      formData: {
        appkey: '',
        udid: '',
        ip: '',
        status: 1, // 状态，1；白名单，2：黑名单，3：灰名单
        timeStart: '',
        timeEnd: '',
        pageNum: 1,
        pageSize: 20
      },
      listInfo: {
        list: [],
        total: 0,
        loading: false,
        deviceNum: 0,
        yesterdayDeviceNum: 0,
        ipNum: 0,
        yesterdayIpNum: 0
      },
      appKeysList: [],
      appKeysNameMap: {},
      pickerOptions: {
        disabledDate (time) {
          const dateTime = time.getTime()
          return dateTime < new Date('2022-3-1').getTime()
        }
      }
    }
  },
  created () {
    this.handleSearch()
    this.getAppKeys()
  },
  methods: {
    dataFomat (row, column, cellValue, index) {
      if (cellValue === '0000-00-00 00:00:00' || !cellValue) { return '-' }
      return cellValue
    },
    appKeyFormatter (row, column, cellValue, index) {
      return this.appKeysNameMap[cellValue] || '-'
    },
    handleSearch () {
      this.formData.pageNum = 1
      this.queryDataList()
    },
    getAppKeys () {
      getAppKeys({
        platform: 2 // 1安卓 2 IOS 用于 黑白名单设备  黑白名单国家 黑白名单客户端 筛选
      }).then(res => {
        if (res.code === 200) {
          this.appKeysList = res?.data?.list ?? []
          this.appKeysNameMap = this.appKeysList.reduce((result, item) => {
            result[item.appKey] = item.name
            return result
          }, {})
        }
      })
    },
    queryDataList () {
      this.listInfo.loading = true
      const [timeStart, timeEnd] = this.searchDate || []
      getBlackAndWhiteDeviceList({
        ...this.formData,
        timeStart,
        timeEnd
      })
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data?.list || []
            this.listInfo.total = res.data?.total || 0
            this.listInfo.deviceNum = res.data?.deviceNum ?? 0
            this.listInfo.yesterdayDeviceNum = res.data?.yesterdayDeviceNum ?? 0
            this.listInfo.ipNum = res.data?.ipNum ?? 0
            this.listInfo.yesterdayIpNum = res.data?.yesterdayIpNum ?? 0
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    updateUserBlackAndWhiteList (row) {
      this.$confirm('是否确定移出白名单？<br> (该条白名单记录将直接从数据库中删除）', '提示', { type: 'warning ', dangerouslyUseHTMLString: true, center: true })
        .then(() => {
          this.$set(row, 'loading', true)
          removeDevice({ id: row.id })
            .then(() => {
              this.queryDataList()
            })
            .finally(() => {
              this.$set(row, 'loading', false)
            })
        })
    }

  }
}
</script>

<style lang="less" scoped>
    .overview {
      display: flex;
      // padding: 0 0 20px 0;
      min-width: 880px;
      margin-bottom: 10px;
      .items {
        width: 210px;
        height: 100px;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, .12);
        border-radius: 4px;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .num {
          font-size: 26px;
          margin-bottom: 10px;
          .unit {
            font-size: 14px;
          }
        }
        .name {
          font-size: 12px;
        }
      }
    }
</style>
