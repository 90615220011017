<template>
  <section>
    <BHeader style="margin: 40px 0;">
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
        @submit.native.prevent
      >
        <el-form-item>
          <el-date-picker
            v-model="searchDate"
            :picker-options="pickerOptions"
            type="daterange"
            format="yyyy-MM-dd"
            unlink-panels
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="handleSearch"
          />
        </el-form-item>
        <el-form-item
          prop="appkey"
        >
          <el-select
            v-model="formData.appKey"
            placeholder="请选择马甲包"
            clearable
            @change="handleSearch"
          >
            <el-option
              v-for="(item, index) in appKeysList"
              :key="index"
              :label="item.name"
              :value="item.appKey"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          prop="listType"
        >
          <el-select
            v-model="formData.listType"
            placeholder="请选择状态"
            clearable
            @change="handleSearch"
          >
            <el-option
              v-for="(item, index) in typeList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item style="margin-left:20px">
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
    </BHeader>
    <el-table
      v-loading="listInfo.loading"
      :data="listInfo.list"
    >
      <el-table-column
        label="应用"
        prop="appKey"
        width="100px"
        :formatter="appKeyFormatter"
      />
      <el-table-column
        prop="version"
        label="版本号"
      />

      <el-table-column
        prop="listType"
        label="状态"
        :formatter="listTypeFormatter"
      />
      <el-table-column
        prop="blackDeviceNum"
        label="黑名单设备数"
        width="140px"
      />
      <el-table-column
        prop="whiteDeviceNum"
        label="白名单设备数"
        width="140px"
      />
      <el-table-column
        label="APPKEY"
        prop="appKey"
        width="160px"
      />

      <el-table-column
        prop="operator"
        label="标记人"
      />

      <el-table-column
        label="标记时间(UTC)"
        prop="markTime"
        :formatter="dataFormatter"
        width="180px"
      />

      <el-table-column
        label="操作"
        width="450px"
      >
        <template #default="{row}">
          <el-button
            type="primary"
            :plain="row.listType === 3"
            :disabled="row.listType === 3"
            :loading="row.loading"
            @click="updateVersionBlackAndWhiteList(row, 3)"
          >
            去除标记
          </el-button>
          <el-button
            type="success"
            :plain="row.listType === 1"
            :disabled="row.listType === 1"
            :loading="row.loading"
            @click="updateVersionBlackAndWhiteList(row, 1)"
          >
            设置白名单
          </el-button>
          <el-button
            type="danger"
            :plain="row.listType === 2"
            :disabled="row.listType === 2"
            :loading="row.loading"
            @click="updateVersionBlackAndWhiteList(row, 2)"
          >
            设置黑名单
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        暂无搜索结果
      </template>
    </el-table>

    <Pagination
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="getVersionBlackAndWhiteList"
    />
  </section>
</template>

<script>
import {
  getVersionBlackAndWhiteList,
  updateVersionBlackAndWhiteList,
  getAppKeys
} from '@/api/blacklist.js'

const typeList = [
  { name: '白名单', value: 1 },
  { name: '黑名单', value: 2 },
  { name: '未设置', value: 3 }
]
const typeMap = {
  1: '白名单',
  2: '黑名单',
  3: '未设置'
}

export default {
  props: {
    vest: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      typeList,
      typeMap,
      searchDate: [],
      formData: {
        timeStart: '',
        timeEnd: '',
        appKey: '',
        listType: '',
        pageNum: 1,
        pageSize: 20
      },
      listInfo: {
        list: [],
        total: 0,
        loading: false
      },
      pickerOptions: {
        disabledDate (time) {
          const dateTime = time.getTime()
          return dateTime < new Date('2022-3-1').getTime()
        }
      },
      appKeysList: [],
      appKeysNameMap: {}
    }
  },
  created () {
    this.getVersionBlackAndWhiteList()
    this.getAppKeys()
  },
  methods: {
    dataFormatter (row, column, cellValue, index) {
      if (cellValue === '0000-00-00 00:00:00' || !cellValue) { return '-' }
      return cellValue
    },
    appKeyFormatter (row, column, cellValue, index) {
      return this.appKeysNameMap[cellValue] || '-'
    },
    listTypeFormatter (row, column, cellValue, index) {
      return this.typeMap[cellValue] || '-'
    },
    handleSearch () {
      this.getVersionBlackAndWhiteList()
    },
    getAppKeys () {
      getAppKeys({
        platform: 2 // 1安卓 2 IOS 用于 黑白名单设备  黑白名单国家 黑白名单客户端 筛选
      }).then(res => {
        if (res.code === 200) {
          this.appKeysList = res?.data?.list ?? []
          this.appKeysNameMap = this.appKeysList.reduce((result, item) => {
            result[item.appKey] = item.name
            return result
          }, {})
        }
      })
    },
    getVersionBlackAndWhiteList () {
      this.listInfo.loading = true
      const [timeStart, timeEnd] = this.searchDate || []
      getVersionBlackAndWhiteList({
        isVest: this.vest,
        ...this.formData,
        timeStart,
        timeEnd
      })
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data.list || []
            this.listInfo.total = res.data.total || 0
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    updateVersionBlackAndWhiteList (row, listType) {
      const { id } = row
      this.$set(row, 'loading', true)
      updateVersionBlackAndWhiteList({
        isVest: this.vest,
        id,
        listType
      })
        .then(res => {
          if (res.code === 200) {
            this.getVersionBlackAndWhiteList()
            this.$message.success('更新成功')
          }
        })
        .finally(() => {
          row.loading = false
        })
    }
  }
}
</script>

<style>

</style>
